import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import './ActivityForm.css';

const ActivityForm = ({ username }) => {
  const [activityData, setActivityData] = useState({
    type: 'activities',
    description: '',
    date: new Date().toISOString().split('T')[0],
    isComplete: false,
    // Optional fields for different types
    startTime: '',
    endTime: '',
    meetingLink: '',
    client: '',
    location: '',
    payment: '',
    shootType: '',
    amount: '',
    dueTime: '',
    priority: 'medium',
    // Reading specific
    pageNumber: '',
    finalPages: '',
    // Notes specific
    category: '',
    tags: []
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSubmit = {
        ...activityData,
        username,
        date: activityData.date
      };

      // Only include fields that have values
      Object.keys(dataToSubmit).forEach(key => {
        if (!dataToSubmit[key] && dataToSubmit[key] !== 0) {
          delete dataToSubmit[key];
        }
      });

      await addDoc(collection(db, 'activities'), dataToSubmit);
      
      setActivityData({
        ...activityData,
        description: '',
        startTime: '',
        endTime: '',
        meetingLink: '',
        client: '',
        location: '',
        payment: '',
        shootType: '',
        amount: '',
        dueTime: '',
        priority: 'medium',
        pageNumber: '',
        finalPages: '',
        category: '',
        tags: []
      });

      window.dispatchEvent(new CustomEvent('activityAdded'));
      alert('Activity added successfully!');
    } catch (error) {
      console.error("Error adding activity:", error);
      alert('Failed to add activity');
    }
  };

  const renderAdditionalFields = () => {
    switch(activityData.type) {
      case 'meetings':
        return (
          <>
            <div className="form-group">
              <label>Start Time (optional)</label>
              <input
                type="time"
                value={activityData.startTime}
                onChange={(e) => setActivityData({
                  ...activityData,
                  startTime: e.target.value
                })}
              />
            </div>
            <div className="form-group">
              <label>End Time (optional)</label>
              <input
                type="time"
                value={activityData.endTime}
                onChange={(e) => setActivityData({
                  ...activityData,
                  endTime: e.target.value
                })}
              />
            </div>
            <div className="form-group">
              <label>Meeting Link (optional)</label>
              <input
                type="url"
                value={activityData.meetingLink}
                onChange={(e) => setActivityData({
                  ...activityData,
                  meetingLink: e.target.value
                })}
                placeholder="https://..."
              />
            </div>
          </>
        );

      case 'shoots':
        return (
          <>
            <div className="form-group">
              <label>Client Name (optional)</label>
              <input
                type="text"
                value={activityData.client}
                onChange={(e) => setActivityData({
                  ...activityData,
                  client: e.target.value
                })}
              />
            </div>
            <div className="form-group">
              <label>Location (optional)</label>
              <input
                type="text"
                value={activityData.location}
                onChange={(e) => setActivityData({
                  ...activityData,
                  location: e.target.value
                })}
              />
            </div>
            <div className="form-group">
              <label>Payment Amount (optional)</label>
              <input
                type="number"
                value={activityData.payment}
                onChange={(e) => setActivityData({
                  ...activityData,
                  payment: e.target.value
                })}
                step="0.01"
              />
            </div>
            <div className="form-group">
              <label>Shoot Type (optional)</label>
              <select
                value={activityData.shootType}
                onChange={(e) => setActivityData({
                  ...activityData,
                  shootType: e.target.value
                })}
              >
                <option value="">Select Type</option>
                <option value="wedding">Wedding</option>
                <option value="portrait">Portrait</option>
                <option value="event">Event</option>
                <option value="commercial">Commercial</option>
                <option value="other">Other</option>
              </select>
            </div>
          </>
        );

      case 'expenses':
        return (
          <div className="form-group">
            <label>Amount (optional)</label>
            <input
              type="number"
              value={activityData.amount}
              onChange={(e) => setActivityData({
                ...activityData,
                amount: e.target.value
              })}
              step="0.01"
            />
          </div>
        );

      case 'deadlines':
        return (
          <>
            <div className="form-group">
              <label>Due Time (optional)</label>
              <input
                type="time"
                value={activityData.dueTime}
                onChange={(e) => setActivityData({
                  ...activityData,
                  dueTime: e.target.value
                })}
              />
            </div>
            <div className="form-group">
              <label>Priority (optional)</label>
              <select
                value={activityData.priority}
                onChange={(e) => setActivityData({
                  ...activityData,
                  priority: e.target.value
                })}
              >
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
              </select>
            </div>
          </>
        );

      case 'reading':
        return (
          <>
            <div className="form-group">
              <label>Current Page</label>
              <input
                type="number"
                value={activityData.pageNumber}
                onChange={(e) => setActivityData({
                  ...activityData,
                  pageNumber: e.target.value
                })}
                min="0"
              />
            </div>
            <div className="form-group">
              <label>Final Page</label>
              <input
                type="number"
                value={activityData.finalPages}
                onChange={(e) => setActivityData({
                  ...activityData,
                  finalPages: e.target.value
                })}
                min="0"
              />
            </div>
          </>
        );

      case 'notes':
        return (
          <>
            <div className="form-group">
              <label>Category</label>
              <input
                type="text"
                value={activityData.category}
                onChange={(e) => setActivityData({
                  ...activityData,
                  category: e.target.value
                })}
                placeholder="e.g., Work, Personal, Study"
              />
            </div>
            <div className="form-group">
              <label>Tags (comma separated)</label>
              <input
                type="text"
                value={activityData.tags.join(', ')}
                onChange={(e) => setActivityData({
                  ...activityData,
                  tags: e.target.value.split(',').map(tag => tag.trim())
                })}
                placeholder="e.g., important, follow-up"
              />
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className="activity-form">
      <h2>Add New Entry</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Date</label>
          <input
            type="date"
            value={activityData.date}
            onChange={(e) => setActivityData({
              ...activityData,
              date: e.target.value
            })}
            required
          />
        </div>
        <div className="form-group">
          <label>Type</label>
          <select
            value={activityData.type}
            onChange={(e) => setActivityData({
              ...activityData,
              type: e.target.value
            })}
            required
          >
            <option value="activities">Activities</option>
            <option value="food">Food</option>
            <option value="reading">Reading</option>
            <option value="workout">Workout</option>
            <option value="todo">To-Do</option>
            <option value="meetings">Meetings</option>
            <option value="shoots">Photo Shoots</option>
            <option value="expenses">Expenses</option>
            <option value="deadlines">Deadlines</option>
            <option value="practice">Practice</option>
            <option value="notes">Notes</option>
          </select>
        </div>

        {renderAdditionalFields()}
        
        <div className="form-group">
          <label>Description</label>
          <textarea
            value={activityData.description}
            onChange={(e) => setActivityData({
              ...activityData,
              description: e.target.value
            })}
            required
            rows="3"
            placeholder="Describe your activity..."
          />
        </div>

        <div className="form-group">
          <label>Status</label>
          <select
            value={activityData.isComplete}
            onChange={(e) => setActivityData({
              ...activityData,
              isComplete: e.target.value === 'true'
            })}
          >
            <option value="false">Incomplete</option>
            <option value="true">Complete</option>
          </select>
        </div>

        <button type="submit" className="submit-button">
          Add Entry
        </button>
      </form>
    </div>
  );
};

export default ActivityForm;