import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import './Login.css';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!username.trim()) {
      setMessage('Username cannot be empty');
      return;
    }

    try {
      // Check if user exists
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where("username", "==", username));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        // Create new user if doesn't exist
        await addDoc(collection(db, 'users'), {
          username: username,
          createdAt: new Date(),
        });
      }

      // Store username in localStorage
      localStorage.setItem('username', username);
      
      // Call the onLogin callback with the username
      onLogin(username);
      setMessage('Logged in successfully!');
    } catch (error) {
      console.error("Error:", error);
      setMessage('Failed to log in. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h1>Daily Tracker</h1>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="Enter your username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="login-input"
          />
          <button type="submit" className="login-button">
            Login
          </button>
        </form>
        {message && <p className={message.includes('Failed') ? 'error-message' : 'success-message'}>{message}</p>}
      </div>
    </div>
  );
};

export default Login;