// src/components/DietAnalysis.js
import React, { useState, useEffect } from 'react';
import './DietAnalysis.css';

const DietAnalysis = ({ activities }) => {
  const [analysis, setAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const analyzeDiet = async (entries) => {
    try {
      const response = await fetch('http://localhost:3001/api/analyze-diet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ foodEntries: entries })
      });

      if (!response.ok) {
        throw new Error('Failed to analyze diet');
      }

      const data = await response.json();
      console.log('Received analysis:', data);
      return data;
    } catch (error) {
      console.error('Error analyzing diet:', error);
      throw error;
    }
  };

  useEffect(() => {
    const getDietAnalysis = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        const today = new Date().toISOString().split('T')[0];
        const todaysFoodEntries = activities.filter(
          activity => activity.type === 'food' && activity.date === today
        );

        if (todaysFoodEntries.length > 0) {
          const result = await analyzeDiet(todaysFoodEntries);
          setAnalysis(result);
        } else {
          setAnalysis(null);
        }
      } catch (err) {
        setError('Failed to analyze diet');
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (activities && activities.length > 0) {
      getDietAnalysis();
    }
  }, [activities]);

  if (isLoading) {
    return (
      <div className="diet-analysis loading">
        <p>Analyzing your diet...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="diet-analysis error">
        <p>{error}</p>
      </div>
    );
  }

  if (!analysis || !analysis.items) {
    return null;
  }

  return (
    <div className="diet-analysis">
      <h3>Today's Diet Analysis</h3>
      
      {/* Daily Totals */}
      <div className="macro-grid">
        <div className="macro-card calories">
          <div className="macro-value">{analysis.totals.calories.toFixed(0)}</div>
          <div className="macro-label">Total Calories</div>
        </div>
        <div className="macro-card protein">
          <div className="macro-value">{analysis.totals.protein.toFixed(1)}g</div>
          <div className="macro-label">Total Protein</div>
        </div>
        <div className="macro-card carbs">
          <div className="macro-value">{analysis.totals.carbs.toFixed(1)}g</div>
          <div className="macro-label">Total Carbs</div>
        </div>
        <div className="macro-card fat">
          <div className="macro-value">{analysis.totals.fat.toFixed(1)}g</div>
          <div className="macro-label">Total Fat</div>
        </div>
      </div>

      {/* Individual Meals */}
      <div className="food-items">
        <h4>Meal Breakdown</h4>
        {analysis.items.map((item, index) => (
          <div key={index} className="food-item">
            <h5>{item.name}</h5>
            <div className="food-macros">
              <div className="macro">
                <span className="macro-label">Calories:</span>
                <span className="macro-value">{item.calories.toFixed(0)}</span>
              </div>
              <div className="macro">
                <span className="macro-label">Protein:</span>
                <span className="macro-value">{item.protein.toFixed(1)}g</span>
              </div>
              <div className="macro">
                <span className="macro-label">Carbs:</span>
                <span className="macro-value">{item.carbs.toFixed(1)}g</span>
              </div>
              <div className="macro">
                <span className="macro-label">Fat:</span>
                <span className="macro-value">{item.fat.toFixed(1)}g</span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Suggestions */}
      {analysis.suggestions && (
        <div className="suggestions-section">
          <h4>Suggestions</h4>
          <ul>
            {analysis.suggestions.map((suggestion, index) => (
              <li key={index}>{suggestion}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DietAnalysis;