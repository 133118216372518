import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, where, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import './ActivityList.css';
import DietAnalysis from './DietAnalysis';

const ActivityList = ({ username }) => {
  const [activities, setActivities] = useState([]);
  const [filterDate, setFilterDate] = useState('');
  const [filterType, setFilterType] = useState('');
  const [editingId, setEditingId] = useState(null);
  const [editData, setEditData] = useState({});
  const [sortKey, setSortKey] = useState('date');
  const [sortOrder, setSortOrder] = useState('asc');
  const [showPastMode, setShowPastMode] = useState(false);

  const getLocalDate = (date = new Date()) => {
    return date.toLocaleDateString('en-CA');
  };

  const compareDates = (date1, date2) => {
    const d1 = new Date(date1 + 'T00:00:00');
    const d2 = new Date(date2 + 'T00:00:00');
    return d1.getTime() - d2.getTime();
  };

  const fetchActivities = async () => {
    try {
      const q = query(
        collection(db, 'activities'),
        where('username', '==', username)
      );

      const querySnapshot = await getDocs(q);
      const activitiesList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        progress: doc.data().type === 'todo' ? (doc.data().progress || 0) : null,
        status: doc.data().type === 'todo' ? (doc.data().status || 'incomplete') : null
      }));

      setActivities(activitiesList);
    } catch (error) {
      console.error("Error fetching activities:", error);
    }
  };

  const updateTodoProgress = async (id, newProgress) => {
    try {
      let progress = Math.min(Math.max(newProgress, 0), 100);
      let status = progress === 100 ? 'complete' : progress === 0 ? 'incomplete' : 'in-progress';
      
      await updateDoc(doc(db, 'activities', id), {
        progress,
        status,
        isComplete: progress === 100
      });
      fetchActivities();
    } catch (error) {
      console.error("Error updating todo progress:", error);
      alert('Failed to update progress');
    }
  };

  const toggleComplete = async (id, currentStatus) => {
    try {
      await updateDoc(doc(db, 'activities', id), {
        isComplete: !currentStatus
      });
      fetchActivities();
    } catch (error) {
      console.error("Error updating completion status:", error);
      alert('Failed to update status');
    }
  };

  const updateReadingProgress = async (id, currentPage) => {
    const newPage = prompt('Enter current page number:', currentPage);
    if (newPage && !isNaN(newPage)) {
      try {
        await updateDoc(doc(db, 'activities', id), {
          pageNumber: parseInt(newPage)
        });
        fetchActivities();
      } catch (error) {
        console.error("Error updating page number:", error);
        alert('Failed to update page number');
      }
    }
  };

  const sortActivities = (activitiesToSort) => {
    return [...activitiesToSort].sort((a, b) => {
      if (sortKey === 'date') {
        const comparison = compareDates(a.date, b.date);
        return sortOrder === 'asc' ? comparison : -comparison;
      }
      if (sortKey === 'type') {
        const comparison = a.type.localeCompare(b.type);
        return sortOrder === 'asc' ? comparison : -comparison;
      }
      return 0;
    });
  };

  useEffect(() => {
    fetchActivities();
    const handleNewActivity = () => {
      fetchActivities();
    };
    window.addEventListener('activityAdded', handleNewActivity);
    return () => {
      window.removeEventListener('activityAdded', handleNewActivity);
    };
  }, [username]);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this activity?')) {
      try {
        await deleteDoc(doc(db, 'activities', id));
        fetchActivities();
      } catch (error) {
        console.error("Error deleting activity:", error);
        alert('Failed to delete activity');
      }
    }
  };

  const startEdit = (activity) => {
    setEditingId(activity.id);
    setEditData({ ...activity });
  };

  const handleEdit = async (id) => {
    try {
      const dataToUpdate = { ...editData };
      delete dataToUpdate.id;
      
      await updateDoc(doc(db, 'activities', id), dataToUpdate);
      setEditingId(null);
      setEditData({});
      fetchActivities();
    } catch (error) {
      console.error("Error updating activity:", error);
      alert('Failed to update activity');
    }
  };

  const handleSort = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortOrder('asc');
    }
  };

  const togglePastMode = () => {
    setShowPastMode(!showPastMode);
    setFilterDate('');
  };

  const filteredActivities = activities.filter(activity => {
    const matchesDate = !filterDate || activity.date === filterDate;  // This is correct
    const matchesType = !filterType || activity.type === filterType;
    const today = getLocalDate();
    
    if (!showPastMode) {
      if (filterDate) {
        return matchesDate && matchesType;  // If date filter is active, only check date and type
      } else {
        // Normal filtering for current/future view
        if (activity.date < today) {
          return matchesType && (activity.type === 'todo' && !activity.isComplete);
        } else {
          return matchesType;
        }
      }
    } else {
      // Past mode
      if (filterDate) {
        return matchesDate && matchesType;
      } else {
        return matchesType && activity.date < today;
      }
    }
  });

  const renderActivityDetails = (activity) => {
    switch(activity.type) {
      case 'meetings':
        return (
          <div className="activity-details">
            {(activity.startTime || activity.endTime) && (
              <div className="time-details">
                <span>Time: {activity.startTime} - {activity.endTime}</span>
              </div>
            )}
            {activity.meetingLink && (
              <div className="meeting-link">
                <a href={activity.meetingLink} target="_blank" rel="noopener noreferrer">
                  Join Meeting
                </a>
              </div>
            )}
          </div>
        );

      case 'shoots':
        return (
          <div className="activity-details">
            {activity.client && <p><strong>Client:</strong> {activity.client}</p>}
            {activity.location && <p><strong>Location:</strong> {activity.location}</p>}
            {activity.shootType && <p><strong>Type:</strong> {activity.shootType}</p>}
            {activity.payment && <p><strong>Payment:</strong> ${parseFloat(activity.payment).toFixed(2)}</p>}
          </div>
        );

      case 'reading':
        return (
          <div className="activity-details">
            <div className="reading-progress">
              <span>Page {activity.pageNumber || 0} of {activity.finalPages || '?'}</span>
              {activity.totalPages && (
                <div className="progress-bar-container">
                  <div 
                    className="progress-bar"
                    style={{ width: `${(activity.pageNumber / activity.finalPages) * 100}%` }}
                  />
                </div>
              )}
            </div>
            <button
              onClick={() => updateReadingProgress(activity.id, activity.pageNumber)}
              className="update-page-btn"
            >
              Update Page
            </button>
          </div>
        );

      case 'notes':
        return (
          <div className="activity-details">
            {activity.category && (
              <span className="note-category">{activity.category}</span>
            )}
            {activity.tags && activity.tags.length > 0 && (
              <div className="tags">
                {activity.tags.map(tag => (
                  <span key={tag} className="tag">{tag}</span>
                ))}
              </div>
            )}
          </div>
        );

      case 'expenses':
        return (
          <div className="activity-details">
            {activity.amount && (
              <p className="expense-amount">
                Amount: ${parseFloat(activity.amount).toFixed(2)}
              </p>
            )}
          </div>
        );

      case 'todo':
        return renderTodoControls(activity);

      default:
        return null;
    }
  };

  const renderTodoControls = (activity) => {
    if (activity.type !== 'todo') return null;

    return (
      <div className="todo-controls">
        <div className="progress-bar-container">
          <div 
            className="progress-bar"
            style={{ width: `${activity.progress}%` }}
          />
          <span className="progress-text">{activity.progress}%</span>
        </div>
        <div className="progress-buttons">
          <button 
            onClick={() => updateTodoProgress(activity.id, activity.progress - 10)}
            className="progress-button"
            disabled={activity.progress <= 0}
          >
            -10%
          </button>
          <button 
            onClick={() => updateTodoProgress(activity.id, activity.progress + 10)}
            className="progress-button"
            disabled={activity.progress >= 100}
          >
            +10%
          </button>
          <button 
            onClick={() => updateTodoProgress(activity.id, 100)}
            className="complete-button"
            disabled={activity.progress === 100}
          >
            Mark Complete
          </button>
          <div className={`status-indicator ${activity.status}`}>
            <span className="status-dot"></span>
            {activity.status}
          </div>
        </div>
      </div>
    );
  };

  const renderEditForm = (activity) => {
    return (
      <div className="edit-form">
        <div className="form-group">
          <label>Description</label>
          <textarea
            value={editData.description || ''}
            onChange={(e) => setEditData({
              ...editData,
              description: e.target.value
            })}
            className="edit-textarea"
          />
        </div>

        {activity.type === 'meetings' && (
          <>
            <div className="form-group">
              <label>Start Time</label>
              <input
                type="time"
                value={editData.startTime || ''}
                onChange={(e) => setEditData({
                  ...editData,
                  startTime: e.target.value
                })}
              />
            </div>
            <div className="form-group">
              <label>End Time</label>
              <input
                type="time"
                value={editData.endTime || ''}
                onChange={(e) => setEditData({
                  ...editData,
                  endTime: e.target.value
                })}
              />
            </div>
            <div className="form-group">
              <label>Meeting Link</label>
              <input
                type="url"
                value={editData.meetingLink || ''}
                onChange={(e) => setEditData({
                  ...editData,
                  meetingLink: e.target.value
                })}
              />
            </div>
          </>
        )}

        {activity.type === 'reading' && (
          <>
            <div className="form-group">
              <label>Current Page</label>
              <input
                type="number"
                value={editData.pageNumber || ''}
                onChange={(e) => setEditData({
                  ...editData,
                  pageNumber: e.target.value
                })}
              />
            </div>
            <div className="form-group">
              <label>Final Page</label>
              <input
                type="number"
                value={editData.totalPages || ''}
                onChange={(e) => setEditData({
                  ...editData,
                  finalPages: e.target.value
                })}
              />
            </div>
          </>
        )}

        {activity.type === 'notes' && (
          <>
            <div className="form-group">
              <label>Category</label>
              <input
                type="text"
                value={editData.category || ''}
                onChange={(e) => setEditData({
                  ...editData,
                  category: e.target.value
                })}
              />
            </div>
            <div className="form-group">
              <label>Tags</label>
              <input
                type="text"
                value={(editData.tags || []).join(', ')}
                onChange={(e) => setEditData({
                  ...editData,
                  tags: e.target.value.split(',').map(tag => tag.trim())
                })}
              />
            </div>
          </>
        )}

        <div className="form-group">
          <label>Status</label>
          <select
            value={editData.isComplete}
            onChange={(e) => setEditData({
              ...editData,
              isComplete: e.target.value === 'true'
            })}
          >
            <option value="false">Incomplete</option>
            <option value="true">Complete</option>
          </select>
        </div>

        <div className="edit-buttons">
          <button 
            onClick={() => handleEdit(activity.id)}
            className="save-button"
          >
            Save
          </button>
          <button 
            onClick={() => {
              setEditingId(null);
              setEditData({});
            }}
            className="cancel-button"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  };

  const sortedActivities = sortActivities(filteredActivities);

  return (
    <div className="activity-list">
        <div className="activity-list-header">
            <h2>Your Entries</h2>
            <div className="controls">
            <button
                onClick={togglePastMode}
                className={`past-toggle-button ${showPastMode ? 'active' : ''}`}
            >
                {showPastMode ? 'Exit Past View' : 'Show Past'}
            </button>
            <div className="filter-group">
                <input
                type="date"
                value={filterDate}
                onChange={(e) => setFilterDate(e.target.value)}
                className="date-filter"
                />
                <select
                value={filterType}
                onChange={(e) => setFilterType(e.target.value)}
                className="type-filter"
                >
                <option value="">All Types</option>
                <option value="activities">Activities</option>
                <option value="food">Food</option>
                <option value="reading">Reading</option>
                <option value="workout">Workout</option>
                <option value="todo">To-Do</option>
                <option value="meetings">Meetings</option>
                <option value="shoots">Photo Shoots</option>
                <option value="expenses">Expenses</option>
                <option value="deadlines">Deadlines</option>
                <option value="practice">Practice</option>
                <option value="notes">Notes</option>
                </select>
            </div>
            <div className="sort-buttons">
                <button
                onClick={() => handleSort('date')}
                className={`sort-button ${sortKey === 'date' ? 'active' : ''}`}
                >
                Date {sortKey === 'date' && (sortOrder === 'desc' ? '↓' : '↑')}
                </button>
                <button
                onClick={() => handleSort('type')}
                className={`sort-button ${sortKey === 'type' ? 'active' : ''}`}
                >
                Type {sortKey === 'type' && (sortOrder === 'desc' ? '↓' : '↑')}
                </button>
            </div>
            </div>
      </div>

      <DietAnalysis activities={activities} />

      <div className="activities">
        {sortedActivities.length === 0 ? (
          <p className="no-activities">No activities found</p>
        ) : (
          sortedActivities.map((activity) => (
            <div key={activity.id} className="activity-card">
              <div className="activity-header">
                <span className="activity-date">{activity.date}</span>
                <span className="activity-type">{activity.type}</span>
                <button
                  onClick={() => toggleComplete(activity.id, activity.isComplete)}
                  className={`complete-toggle ${activity.isComplete ? 'complete' : 'incomplete'}`}
                >
                  {activity.isComplete ? 'Complete' : 'Incomplete'}
                </button>
              </div>
              {editingId === activity.id ? (
                renderEditForm(activity)
              ) : (
                <>
                  <p className="activity-description">{activity.description}</p>
                  {renderActivityDetails(activity)}
                  <div className="activity-actions">
                    <button 
                      onClick={() => startEdit(activity)}
                      className="edit-button"
                    >
                      Edit
                    </button>
                    <button 
                      onClick={() => handleDelete(activity.id)}
                      className="delete-button"
                    >
                      Delete
                    </button>
                  </div>
                </>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ActivityList;