import React, { useState, useEffect, useCallback } from 'react';
import { db } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import './TimeTracker.css';

const TimeTracker = ({ username }) => {
  const [dob, setDob] = useState(null);
  const [tempDob, setTempDob] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [timeStats, setTimeStats] = useState({
    careerProgress: 0,
    secondsToNextPercent: 0,
    activeMinutesLeft: 0
  });

  const updateTimes = useCallback(() => {
    if (!dob) return;
  
    const birthDate = new Date(dob);
    const now = new Date();
    
    // Career spans 61 years
    const careerEnd = new Date(birthDate);
    careerEnd.setFullYear(careerEnd.getFullYear() + 75);
    
    const careerStart = new Date(birthDate);
    careerStart.setFullYear(careerStart.getFullYear() + 14);
  
    const totalCareerSeconds = (careerEnd - careerStart) / 1000; // 61 years in seconds
    const secondsPerPercent = totalCareerSeconds / 100; // ~19,230,912 seconds per percent
    const secondsSpent = (now - careerStart) / 1000;
    
    const progressPercent = (secondsSpent / totalCareerSeconds) * 100;
    const currentPercent = Math.floor(progressPercent);
    
    const secondsToNextPercent = (secondsPerPercent * (currentPercent + 1)) - secondsSpent;
  
    setTimeStats({
      careerProgress: currentPercent,
      secondsToNextPercent: Math.max(0, Math.ceil(secondsToNextPercent)),
      activeMinutesLeft: Math.floor((careerEnd - now) / 1000 / 60 * (16/24)).toLocaleString()
    });
  }, [dob]);

  // Keep existing helper functions
  const fetchUserDOB = useCallback(async () => {
    try {
      const userRef = doc(db, 'users', username);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.dob) {
          setDob(userData.dob);
          setTempDob(userData.dob);
        }
      }
    } catch (error) {
      console.error("Error fetching DOB:", error);
    }
  }, [username]);

  useEffect(() => {
    fetchUserDOB();
  }, [fetchUserDOB]);

  useEffect(() => {
    const timer = setInterval(updateTimes, 1000);
    return () => clearInterval(timer);
  }, [updateTimes]);

  const startEditing = () => {
    setIsEditing(true);
    setTempDob(dob || '');
  };

  const cancelEditing = () => {
    setIsEditing(false);
    setTempDob(dob || '');
  };

  const clearDOB = () => {
    setTempDob('');
  };

  const saveDOB = async () => {
    if (!tempDob) return;
    try {
      const userRef = doc(db, 'users', username);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        await updateDoc(userRef, { dob: tempDob });
      } else {
        await setDoc(userRef, { username, dob: tempDob });
      }
      setDob(tempDob);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating DOB:", error);
    }
  };

  const formatTime = (seconds) => {
    // Keep years and days calculations the same
    const years = Math.floor(seconds / (365.25 * 24 * 60 * 60));
    const days = Math.floor((seconds % (365.25 * 24 * 60 * 60)) / (24 * 60 * 60));
    
    // Apply 16/24 ratio only to the remaining hours, minutes, seconds
    const remainingSeconds = seconds % (24 * 60 * 60);
    const activeSeconds = remainingSeconds * (16/24);
    
    const hours = Math.floor(activeSeconds / (60 * 60));
    const minutes = Math.floor((activeSeconds % (60 * 60)) / 60);
    const secs = Math.floor(activeSeconds % 60);
  
    return `${years}y ${days}d ${hours}h ${minutes}m ${secs}s`;
  };

  return (
    <div className="time-tracker">
      <div className="time-tracker-header">
        <h2>Time Perspective</h2>
        {!isEditing ? (
          <button onClick={startEditing} className="edit-button">
            {dob ? 'Edit DOB' : 'Set DOB'}
          </button>
        ) : (
          <div className="dob-edit">
            <input 
              type="date"
              value={tempDob}
              onChange={(e) => setTempDob(e.target.value)}
              className="dob-input"
            />
            <div className="dob-buttons">
              <button onClick={clearDOB} className="clear-button">Clear</button>
              <button onClick={saveDOB} className="save-button" disabled={!tempDob}>Save</button>
              <button onClick={cancelEditing} className="cancel-button">Cancel</button>
            </div>
          </div>
        )}
      </div>
      {dob ? (
        <div className="time-stats">
          <div className="stat-card career-time">
            <div className="stat-main">
              <h3>Career Progress (14-75)</h3>
              <div className="progress-container">
                <p className="career-percentage">{timeStats.careerProgress}%</p>
                <div className="progress-bar2">
                  <div 
                    className="progress-fill"
                    style={{ width: `${timeStats.careerProgress}%` }}
                  ></div>
                </div>
              </div>
              <div className="next-percent">
                <p className="next-percent-heading">
                    Time until {timeStats.careerProgress + 1}%: {formatTime(timeStats.secondsToNextPercent)}
                    <br/>
                    ({Math.floor(timeStats.secondsToNextPercent/60).toLocaleString()} minutes)
                </p>
                <p className="active-time-note">
                    * Based on 16 active hours per day
                </p>
                </div>
                
            </div>
            <div className="stat-minutes">
              <span className="minutes-label">Active Minutes Remaining:</span>
              <span className="minutes-value">{timeStats.activeMinutesLeft}</span>
            </div>
          </div>
        </div>
      ) : (
        <p className="no-dob">Please set your date of birth to see time calculations</p>
      )}
    </div>
  );
};

export default TimeTracker;