import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDO10emEhoU3agDPo9AF7nNHcLYvrpQxvg",
  authDomain: "tracker-857c4.firebaseapp.com",
  projectId: "tracker-857c4",
  storageBucket: "tracker-857c4.firebasestorage.app",
  messagingSenderId: "435127681797",
  appId: "1:435127681797:web:1028fbbdd36d2cc5706d6c",
  measurementId: "G-41R7RR5TXE"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);