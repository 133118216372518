import React, { useState, useEffect } from 'react';
import Login from './components/Login';
import ActivityForm from './components/ActivityForm';
import ActivityList from './components/ActivityList';
import TimeTracker from './components/TimeTracker';
import './App.css';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const savedUsername = localStorage.getItem('username');
    if (savedUsername) {
      setUser(savedUsername);
    }
  }, []);

  const handleLogin = (username) => {
    setUser(username);
  };

  const handleLogout = () => {
    localStorage.removeItem('username');
    setUser(null);
  };

  return (
    <div className="app">
      {!user ? (
        <Login onLogin={handleLogin} />
      ) : (
        <div className="dashboard">
          <header>
            <h1>Welcome, {user}!</h1>
            <button onClick={handleLogout} className="logout-button">
              Logout
            </button>
          </header>
          <div className="dashboard-content">
            <TimeTracker username={user} />
            <ActivityForm username={user} />
            <ActivityList username={user} />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;